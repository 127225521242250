:root {
    --purple: #5144d1;
    --aqua: #12e8c4;
    --green: #a1e673;
    --white: #fafaff;
    --yellow: #f5f094;
}

#m2m-header {
    background-color: var(--white);
    height: 10vh;
    width: 100%;
    top: 0;
    position: sticky;
    z-index: 2;
}

#m2m-logo {
    height: 7vh;
}

#m2m-title-bar {
    position: sticky;
    top: 10vh;
    z-index: 2;
    font-size: 4vmin;
    background-color: var(--purple);
    color: var(--white);
}

#m2m-title-bar-title {
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    text-align: left;
}

#m2m-title-bar-button {
    background-color: var(--purple);
    z-index: 2;
}

#m2m-title-bar-button Button {
    position: absolute;
    right: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#m2m-menu-sidebar {
    background-color: var(--aqua);
    width: 360px;
}

#m2m-menu-sidebar-title {
    color: var(--purple);
    font-weight: 800;
    font-size: xx-large;
}

#m2m-pagination {
    color: var(--purple);
    font-weight: 600;
    font-size: larger;
}

#embeded-video-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

#embeded-video-container iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.App {
    text-align: center;
}

.m2m-button {
    border-color: var(--purple);
    border-width: 2px;
    background-color: var(--yellow);
    color: var(--purple);
}

.m2m-button:hover {
    background-color: var(--purple);
    color: var(--yellow);
}

.m2m-button:disabled {
    border-color: var(--aqua);
    background-color: var(--white);
    color: var(--aqua);
}

.m2m-link {
    /* color: var(--purple); */
    font-weight: 400;
}

.m2m-link:hover {
    /* color: var(--green); */
    font-weight: 600;
}

.m2m-link-2 {
    color: var(--purple);
}

.m2m-link-2:hover {
    color: var(--aqua);
}

.m2m-table {
    width: 85vw;
}

.m2m-table-scroll {
    overflow: scroll;
    overflow-x: hidden;
    height: 65vh;
}

.m2m-table Table {
    vertical-align: middle;
    color: var(--purple);
}

.m2m-table img {
    width: 15vw;
}

.m2m-play-button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.75;
    font-size: 4vmax;
    padding-top: 0;
    padding-bottom: 0;
    color: var(--green);
}

.m2m-play-button:hover {
    color: var(--purple);
}

.player-modal {
    background-color: var(--yellow);
    color: var(--purple);
}

.section-button {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.1rem;
    border-width: 2px;
}

.section-button:hover {
    font-weight: 800;
    font-size: 1.25rem;
}

.title-capsule {
    /* background-color: var(--yellow);
    color: var(--purple); */
    border-radius: 20px;
    border-style: solid;
    border-width: 2px;
    /* overflow: ; */
}